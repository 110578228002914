import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { AssetCodeFormatter } from '../../../../components/AssetCodeFormatter';
import Button from '../../../../components/button/Button';
import { useGetAccountCode, useGetAccountDetails } from '../../../../helpers/useGetAccountDetails';
import { AccountFeeInfo, MakePaymentForm, PaymentStepProps } from './MakePayment';
import styles from './MakePayment.module.scss';
import api, { ApiResponse, isAxiosErrorHandled } from '../../../../api';
import { endpoints } from '../../../../endpoints.config';
import { Toast, ToastMessageReason } from '../../../../helpers/toast';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomerComponentResources } from '../../../../reducers/componentResources';
import { completeUIUpdate } from '../../../../components/notifications/notificationUIUpdateReducer';
import { getFeeLabel } from './AmountPurpose';
import { getRelevantPayloadFields, useIsFinancialInstitution } from '../helpers';

type Props = PaymentStepProps & {
    data: MakePaymentForm;
    purposes: SelectOption[];
    accountFeeInfo: AccountFeeInfo;
    availableCountries: {
        label: string;
        value: string;
    }[];
    handleEditPayee: () => void;
};

export const ReviewPayment: React.FC<Props> = ({
    data,
    purposes,
    accountFeeInfo,
    nextStep,
    handleEditPayee,
    availableCountries,
}) => {
    const bFinancialInstitution = useIsFinancialInstitution();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const account = useGetAccountDetails();
    const currencyCode = useGetAccountCode();

    const dispatch = useDispatch();

    const [showEditPayeeButton, setshowEditPayeeButton] = useState(false);

    const submitPayment = async () => {
        setIsSubmitting(true);

        const formdata = new FormData();

        const { id, paymentReference, attachments, feeId, ...payload } =
            getRelevantPayloadFields(data);

        formdata.append('customerAssetAccountsId', account?.id + '');
        formdata.append('payeesId', id + '');
        feeId && formdata.append('bankFeeSplitType', feeId + '');
        formdata.append('reference', paymentReference);
        Object.entries(payload).forEach(([key, value]) => {
            formdata.append(key, value + '');
        });
        attachments.forEach((file) => {
            formdata.append('attachments', file);
        });

        try {
            const res = await api.post<
                ApiResponse<{
                    bApproved: boolean;
                }>
            >(endpoints.accounts.createExternalTransfer, formdata, {
                headers: { 'content-type': 'multipart/form-data' },
            });

            if (res.data.status === '1') {
                if (!res.data.details.bApproved) {
                    Toast.openToastMessage(
                        "Your transaction has been flagged for review. The funds have been reserved and we'll let you know when it has been approved",
                        ToastMessageReason.ERROR,
                        { autoClose: false }
                    );
                    dispatch(getCustomerComponentResources());
                    dispatch(completeUIUpdate());
                } else {
                    Toast.openSuccessToast('Transfer succeeded');
                    dispatch(getCustomerComponentResources());
                    dispatch(completeUIUpdate());
                }
                nextStep();
            }
        } catch (err) {
            if (isAxiosErrorHandled(err)) {
                const errors = err.response.data.errors;
                if (
                    errors.some(
                        (err) => err.fieldName === 'PayeesId' && err.messageCode === 'Invalid'
                    )
                ) {
                    Toast.openToastMessage('Invalid Payee', ToastMessageReason.ERROR);
                } else if (
                    errors.some((err) => err.fieldName.toLowerCase().includes('transmitter'))
                ) {
                    setshowEditPayeeButton(true);
                    Toast.openErrorToast('Missing transmitting info');
                } else Toast.parseErrorIntoToast(err.response.data.errors?.[0]?.messageCode);
            } else Toast.openGenericErrorToast();
        } finally {
            setIsSubmitting(false);
        }
    };

    const purpose = purposes.find((item) => item.value === data.purpose);
    const country =
        availableCountries.find((item) => item.value === data.countryCode)?.label ??
        data.countryCode;
    const payeeCountry =
        availableCountries.find((item) => item.value === data.addressCountryCode)?.label ??
        data.addressCountryCode;

    const feeOptions =
        accountFeeInfo.transferTypes.find(
            (fee) => fee.transferType.toLowerCase() === data.transferType.toLowerCase()
        )?.feeOptions ?? [];
    const fee = feeOptions.find((fee) => fee.bankFeeSplitType === data.feeId);

    return (
        <div className={styles.ReviewPayment}>
            <div className={styles.Layout}>
                <div>
                    <h4>Recipient</h4>
                    <dl className={styles.Recipient}>
                        <div className={styles.Row}>
                            <dt>Account Type:</dt>
                            <dd>{data.payeeType || '-'}</dd>
                        </div>
                        <div className={styles.Row}>
                            <dt>Account Country:</dt>
                            <dd>{country || '-'}</dd>
                        </div>
                        <div className={styles.Row}>
                            <dt>Account Name:</dt>
                            <dd>{data.accountName || '-'}</dd>
                        </div>
                        <div className={styles.Row}>
                            <dt>Payee Name:</dt>
                            <dd>{data.name}</dd>
                        </div>

                        <div className={styles.Row}>
                            <dt>Payee Country:</dt>
                            <dd>{payeeCountry || '-'}</dd>
                        </div>
                        <div className={styles.Row}>
                            <dt>Payee Reference:</dt>
                            <dd>{data.payeesReference || '-'}</dd>
                        </div>
                        {data.swiftNumber && (
                            <div className={styles.Row}>
                                <dt>Swift Number:</dt>
                                <dd>{data.swiftNumber}</dd>
                            </div>
                        )}
                        {data.intermediaryBic && (
                            <div className={styles.Row}>
                                <dt>Intermediary Swift:</dt>
                                <dd>{data.intermediaryBic}</dd>
                            </div>
                        )}
                        {data.iban && (
                            <div className={styles.Row}>
                                <dt>IBAN:</dt>
                                <dd>{data.iban}</dd>
                            </div>
                        )}
                        {data.routingNumber && (
                            <div className={styles.Row}>
                                <dt>Routing Number:</dt>
                                <dd>{data.routingNumber}</dd>
                            </div>
                        )}
                        {data.accountNumber && (
                            <div className={styles.Row}>
                                <dt>Account Number:</dt>
                                <dd>{data.accountNumber}</dd>
                            </div>
                        )}
                    </dl>
                </div>
                <div>
                    <h4>Payment details</h4>
                    <dl>
                        <div className={styles.Row}>
                            <dt>Payment Method:</dt>
                            <dd>{data.transferType}</dd>
                        </div>
                        <div className={styles.Row}>
                            <dt>Payment Reference:</dt>
                            <dd>{data.paymentReference || '-'}</dd>
                        </div>
                    </dl>

                    <h4>Amount & purpose</h4>
                    <dl>
                        {fee && feeOptions.length > 1 && (
                            <div className={styles.Row}>
                                <dt>Fee:</dt>
                                <dd>{getFeeLabel(fee.bankFeeSplitType).label}</dd>
                            </div>
                        )}
                        <div className={styles.Row}>
                            <dt>Amount:</dt>
                            <dd>
                                {data.amount} <AssetCodeFormatter assetCode={currencyCode} />
                            </dd>
                        </div>
                        <div className={styles.Row}>
                            <dt>{data.transferType} Payment Purpose:</dt>
                            <dd>{purpose ? purpose.label : data.purpose || '-'}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <h4>More details</h4>
            <dl>
                {!bFinancialInstitution && (
                    <div className={styles.Row}>
                        <dt>Memo:</dt>
                        <dd>{data.memo || '-'}</dd>
                    </div>
                )}
                <div className={styles.Row}>
                    <dt>Attachments:</dt>
                    <dd>
                        {data.attachments.length > 0
                            ? data.attachments.reduce(
                                  (prev, cur, i) => prev + `${i !== 0 ? ', ' : ''}${cur.name}`,
                                  ''
                              )
                            : '-'}
                    </dd>
                </div>
                <div className={styles.Row}>
                    <dt>Notes:</dt>
                    <dd>{data.notes || '-'}</dd>
                </div>
            </dl>

            {showEditPayeeButton && (
                <Button
                    className="MarginBottom"
                    type="button"
                    priority="secondary"
                    disabled={isSubmitting}
                    onClick={handleEditPayee}
                    color="first"
                >
                    Edit Payee
                </Button>
            )}

            <Button disabled={isSubmitting} onClick={submitPayment} color="third">
                Confirm Payment
            </Button>
        </div>
    );
};
