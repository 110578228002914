import { FormikErrors } from 'formik';
import { Collapse } from '../../../components/Collapse/Collapse';
import { BulkTransferItemHeader } from './BulkTransferItemHeader';
import { BulkTransferOptions, ParsedCsv } from './models';
import classNames from 'classnames';
import React from 'react';
import { csvCellConfig, findState } from './helpers';
import { useIsFinancialInstitution } from '../Payees/helpers';

type Props = ParsedCsv & {
    accounts: BulkTransferOptions['accounts'];
    countries: BulkTransferOptions['countries'];
    purposeCodes: BulkTransferOptions['purposeCodes'];
    handleDeleteTransfer(): void;
    handleEditTransfer(): void;
    errors: FormikErrors<ParsedCsv> | string | undefined;
};

export const BulkTransferItem: React.FC<Props> = ({
    accounts,
    countries,
    purposeCodes,
    errors,
    ...transfer
}) => {
    const bFinancialInstitution = useIsFinancialInstitution();
    const account = (accounts ?? []).find(
        (acc) => acc.id.toString() === transfer.sourceAccountId?.toString()
    );

    const accountCountry = !transfer.countryCode
        ? null
        : countries.find((country) => country.countryISO3 === transfer.countryCode)?.name ?? null;

    const addressCountry = !transfer.addressCountryCode
        ? null
        : countries.find((country) => country.countryISO3 === transfer.addressCountryCode);
    const addressCountryName = addressCountry?.name ?? null;
    const addressState =
        addressCountry && transfer.state
            ? findState(transfer.state, addressCountry)?.name
            : transfer.state;

    // Transmitter
    const transmitterCountry =
        transfer.transmitter && transfer.transmitter.countryCode
            ? countries.find((country) => country.countryISO3 === transfer.transmitter?.countryCode)
            : null;
    const transmitterCountryName = transmitterCountry?.name ?? null;
    const transmitterState =
        transfer.transmitter && transmitterCountry && transfer.transmitter.state
            ? findState(transfer.transmitter.state, transmitterCountry)?.name
            : transfer.state;

    const header = () => (
        <BulkTransferItemHeader
            currencyCode={account?.currencyCode}
            currencySymbol={account?.currencySymbol}
            sourceAccountName={account?.accountName}
            {...transfer}
        />
    );

    const memoNotesLengthExceeded =
        (transfer.memo ?? '').length + (transfer.notes ?? '').length > 800;

    const isUSA = !transfer.countryCode || transfer.countryCode === 'USA';
    return (
        <>
            <Collapse
                hideArrow
                showCollapseArrow
                header={header()}
                className={classNames({
                    HasError: errors || transfer.status === 'Error',
                    Success:
                        transfer.status === 'Success' ||
                        transfer.status === 'Success - Pending Approval',
                })}
            >
                <div
                    className={`BulkTransferItemContent ${
                        memoNotesLengthExceeded || bFinancialInstitution ? 'Wrap' : ''
                    }`}
                >
                    <div>
                        <LabelValuePair label="Payee Name" value={transfer.name} />
                        <LabelValuePair label="Account Name" value={transfer.accountName} />
                        <LabelValuePair
                            classname="MarginBottom"
                            label="Account Country"
                            value={accountCountry}
                        />

                        {!transfer.isDbs && isUSA && (
                            <LabelValuePair label="Routing" value={transfer.routingNumber} />
                        )}
                        {(transfer.isDbs || (!transfer.isDbs && isUSA)) && (
                            <LabelValuePair label="Account" value={transfer.accountNumber} />
                        )}
                        {(transfer.isDbs || !isUSA) && (
                            <LabelValuePair label="SWIFT" value={transfer.swiftNumber} />
                        )}
                        {transfer.isDbs && transfer.intermediaryBic && (
                            <LabelValuePair
                                label="Intermediary Swift"
                                value={transfer.intermediaryBic}
                            />
                        )}
                        {!transfer.isDbs && !isUSA && (
                            <LabelValuePair label="IBAN" value={transfer.iban} />
                        )}
                    </div>

                    <div>
                        <LabelValuePair label="Address Line 1" value={transfer.addressLine1} />
                        {transfer.addressLine2 && (
                            <LabelValuePair label="Address Line 2" value={transfer.addressLine2} />
                        )}
                        <LabelValuePair label="Town / City" value={transfer.townCity} />
                        <LabelValuePair label="State" value={addressState} />
                        <LabelValuePair label="ZIP code" value={transfer.postCode} />
                        <LabelValuePair label="Country" value={addressCountryName} />
                    </div>

                    {bFinancialInstitution && transfer.transmitter && (
                        <div>
                            <LabelValuePair label="Transmitter Info" value={''} />
                            <LabelValuePair
                                label="First Party Transfer"
                                value={transfer.transmitter.firstPartyTransfer ? 'Yes' : 'No'}
                            />
                            {!transfer.transmitter.firstPartyTransfer && (
                                <>
                                    <LabelValuePair
                                        label="Name"
                                        value={transfer.transmitter.name}
                                    />
                                    {transfer.transmitter.dateOfBirth && (
                                        <LabelValuePair
                                            label="Date of Birth"
                                            value={transfer.transmitter.dateOfBirth}
                                        />
                                    )}
                                    <LabelValuePair
                                        label="Account Number"
                                        value={transfer.transmitter.accountNumber}
                                    />
                                    <LabelValuePair
                                        label="Address Line 1"
                                        value={transfer.transmitter.addressLine1}
                                    />
                                    {transfer.transmitter.addressLine2 && (
                                        <LabelValuePair
                                            label="Address Line 2"
                                            value={transfer.addressLine2}
                                        />
                                    )}
                                    <LabelValuePair
                                        label="Town / City"
                                        value={transfer.transmitter.townCity}
                                    />
                                    <LabelValuePair label="State" value={transmitterState} />
                                    <LabelValuePair
                                        label="ZIP code"
                                        value={transfer.transmitter.postcode}
                                    />
                                    <LabelValuePair
                                        label="Country"
                                        value={transmitterCountryName}
                                    />
                                </>
                            )}
                        </div>
                    )}

                    <div className="MemoNotes">
                        {!bFinancialInstitution && (
                            <LabelValuePair classname="Memo" label="Memo" value={transfer.memo} />
                        )}
                        <LabelValuePair label="Notes" value={transfer.notes} />
                    </div>
                </div>
            </Collapse>
            {parseError(errors)}
        </>
    );
};

const parseError = (error: FormikErrors<ParsedCsv> | string | undefined): React.ReactNode => {
    if (!error) return null;
    if (typeof error == 'string') return <div className="ErrorLabel">{error}</div>;

    return (
        <div className="ErrorLabel">
            {Object.entries(error).map(([key, value], i) => {
                const fieldLabel = csvCellConfig.find((header) => header.fieldValue === key)?.label;

                return (
                    <React.Fragment key={i}>
                        <div>
                            {fieldLabel ? `${fieldLabel} - ` : ''}
                            {typeof value === 'string' && value}
                            {typeof value !== 'string' &&
                                key === 'transmitter' &&
                                'Transmitter fields mising'}
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

const LabelValuePair = ({
    label,
    value,
    classname = '',
}: {
    label: string;
    value?: string | null;
    classname?: string;
}) => (
    <div className={`LabelValuePair ${classname}`}>
        <div className="Label">{label}:</div>
        <div className="Value">{value ?? '-'}</div>
    </div>
);
