import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik';
import { useEffect } from 'react';

export const useTouchErrorFormikFields = (touchWithoutErrors = false, deps = [] as any[]) => {
    const { validateForm, setTouched } = useFormikContext();

    useEffect(() => {
        (async () => {
            const errors = await validateForm();
            if (Object.keys(errors).length === 0 && !touchWithoutErrors) {
                // Do nothing! No need to touch anything no errors to surface
            } else {
                /* Quickest way to touch all fields. 
                Important we check there are errors so we don't submit the form for the user */
                setTouched(setNestedObjectValues<FormikTouched<any>>(errors, true));
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
