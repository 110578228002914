import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ProductType } from '../../../components/sideMenu/SideMenu';
import { useGetFiatAccountDetails } from '../../../helpers/useGetFiatAccountDetails';
import { selectUserInfo } from '../../../reducers/componentResources';
import { ErrorCodes, selectErrorCodes } from '../../../reducers/errorCodes';
import { ErrorCountry } from './MakePayment/Recipient';
import { MakePaymentForm } from './MakePayment/MakePayment';
import {
    EditPayeeResponse,
    EMPTY_PAYEE_VALUES,
    EMPTY_TRANSMITTER_INFO,
    PayeeFormState,
    TransmitterType,
} from './EditPayee';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import { useFetch } from '../../../api';
import { endpoints } from '../../../endpoints.config';
import { useEffect, useState } from 'react';

const validationSchemaCRB = (errorCountries: ErrorCountry[], errorCodes: ErrorCodes) =>
    Yup.object({
        accountName: Yup.string().required('Please enter an account name').nullable(),
        countryCode: Yup.string()
            .required('Please select a country')
            .nullable()
            .test('hasCountryError', 'countryError', function (val) {
                if (!val) return true;

                const selectedCountry = errorCountries.find((cou) => cou.countryISO3 === val);

                const countryErrorCode = selectedCountry?.errorCode;
                if (countryErrorCode) {
                    const msg = errorCodes[countryErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                }

                return true;
            }),
        name: Yup.string().required('Please enter a name').nullable(),
        addressLine1: Yup.string().required('Please enter an address').nullable(),
        townCity: Yup.string().required('Please enter an town or city').nullable(),
        postCode: Yup.string().required('Please enter a valid post/zip code').nullable(),
        addressCountryCode: Yup.string()
            .required('Please select a country')
            .nullable()
            .test('hasCountryError', 'countryError', function (val) {
                if (!val) return true;

                const selectedCountry = errorCountries.find((cou) => cou.countryISO3 === val);

                const countryErrorCode = selectedCountry?.errorCode;
                if (countryErrorCode) {
                    const msg = errorCodes[countryErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                }

                return true;
            }),
        routingNumber: Yup.string()
            .nullable()
            .when('countryCode', {
                is: 'USA',
                then: Yup.string().required('Please enter a routing number'),
            }),
        accountNumber: Yup.string()
            .nullable()
            .when('countryCode', {
                is: 'USA',
                then: Yup.string().nullable().required('Please enter an account number'),
            }),
        swiftNumber: Yup.string()
            .nullable()
            .when('countryCode', {
                is: (value: string) => value !== 'USA',
                then: Yup.string().required('Please enter a swift number'),
            }),
        iban: Yup.string()
            .nullable()
            .when('countryCode', {
                is: (value: string) => value !== 'USA',
                then: Yup.string().required('Please enter an IBAN'),
            }),
        payeesReference: Yup.string()
            .required(`Please enter payee's reference`)
            .max(16, 'Reference cannot be longer than 16 characters')
            .matches(/^[\w\d ]*$/, 'Reference must not contain special characters')
            .nullable(),
        bankName: Yup.string()
            .required('Please enter the name of the destination bank')
            .max(35, 'Maximum 35 characters')
            .nullable(),

        bFinancialInstitution: Yup.boolean(),
        transmitter: createTransmitterSchema(errorCountries, errorCodes),
    });
const validationSchemaDBS = (errorCountries: ErrorCountry[], errorCodes: ErrorCodes) =>
    Yup.object({
        accountName: Yup.string().required('Please enter an account name').nullable(),
        countryCode: Yup.string()
            .required('Please select a country')
            .nullable()
            .test('hasCountryError', 'countryError', function (val) {
                if (!val) return true;

                const selectedCountry = errorCountries.find((cou) => cou.countryISO3 === val);

                const countryErrorCode = selectedCountry?.errorCode;
                if (countryErrorCode) {
                    const msg = errorCodes[countryErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                }

                return true;
            }),
        name: Yup.string().required('Please enter a name').nullable(),
        addressLine1: Yup.string().required('Please enter an address').nullable(),
        townCity: Yup.string().required('Please enter an town or city').nullable(),
        postCode: Yup.string().required('Please enter a valid post/zip code').nullable(),
        addressCountryCode: Yup.string()
            .required('Please select a country')
            .nullable()
            .test('hasCountryError', 'countryError', function (val) {
                if (!val) return true;

                const selectedCountry = errorCountries.find((cou) => cou.countryISO3 === val);

                const countryErrorCode = selectedCountry?.errorCode;
                if (countryErrorCode) {
                    const msg = errorCodes[countryErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                }

                return true;
            }),
        accountNumber: Yup.string().required('Please enter an account number').nullable(),
        swiftNumber: Yup.string().required('Please enter a swift number').nullable(),
        intermediaryBic: Yup.string().nullable(),
        payeesReference: Yup.string()
            .required(`Please enter payee's reference`)
            .max(16, 'Reference cannot be longer than 16 characters')
            .matches(/^[\w\d ]*$/, 'Reference must not contain special characters')
            .nullable(),
        bankName: Yup.string()
            .required('Please enter the name of the destination bank')
            .max(35, 'Maximum 35 characters')
            .nullable(),

        bFinancialInstitution: Yup.boolean(),
        transmitter: createTransmitterSchema(errorCountries, errorCodes),
    });

export const usePayeeValidationSchema = (errorCountries: ErrorCountry[]) => {
    const accountDetails = useGetFiatAccountDetails();
    const errorCodes = useSelector(selectErrorCodes);

    return accountDetails?.productDisplayName === ProductType.DBS ||
        accountDetails?.productDisplayName === ProductType.DBS_TMP
        ? validationSchemaDBS(errorCountries, errorCodes)
        : validationSchemaCRB(errorCountries, errorCodes);
};

const createTransmitterSchema = (errorCountries: ErrorCountry[], errorCodes: ErrorCodes) =>
    Yup.object()
        .nullable()
        .when('bFinancialInstitution', {
            is: true,
            then: transmitterObjectSchema(errorCountries, errorCodes),
        });

export const transmitterObjectSchema = (errorCountries: ErrorCountry[], errorCodes: ErrorCodes) =>
    Yup.object().shape({
        firstPartyTransfer: Yup.boolean().required('Required').nullable(),
        type: Yup.string()
            .nullable()
            .when('firstPartyTransfer', {
                is: false,
                then: (schema) => schema.required('Required'),
            }),
        name: Yup.string()
            .nullable()
            .when('firstPartyTransfer', {
                is: false,
                then: (schema) =>
                    schema
                        .required('Please enter the transmitter name')
                        .max(100, 'Maximum 100 characters'),
            }),
        dateOfBirth: Yup.string()
            .nullable()
            .when(['firstPartyTransfer', 'type'], {
                is: (firstPartyTransfer: boolean, type: TransmitterType) =>
                    !firstPartyTransfer && type === TransmitterType.Personal,
                then: (schmea) => schmea.required('Please provide a date of birth'),
            }),
        accountNumber: Yup.string().max(100, 'Maximum 100 characters').nullable(),
        addressLine1: Yup.string()
            .nullable()
            .when('firstPartyTransfer', {
                is: false,
                then: (schema) =>
                    schema.required('Please enter an address').max(100, 'Maximum 100 characters'),
            }),
        addressLine2: Yup.string().max(100, 'Maximum 100 characters').nullable(),
        townCity: Yup.string()
            .nullable()
            .when('firstPartyTransfer', {
                is: false,
                then: (schema) =>
                    schema
                        .required('Please enter an town or city')
                        .max(100, 'Maximum 100 characters'),
            }),
        state: Yup.string()
            .nullable()
            .when('firstPartyTransfer', {
                is: false,
                then: (schema) => schema.required('Required').max(100, 'Maximum 100 characters'),
            }),
        postcode: Yup.string()
            .nullable()
            .when('firstPartyTransfer', {
                is: false,
                then: (schema) =>
                    schema
                        .required('Please enter a valid post/zip code')
                        .max(100, 'Maximum 100 characters'),
            }),
        countryCode: Yup.string()
            .nullable()
            .when('firstPartyTransfer', {
                is: false,
                then: (schema) => schema.required('Please select a country'),
            })
            .test('hasCountryError', 'countryError', function (val) {
                if (!val || this.parent.firstPartyTransfer) return true;

                const selectedCountry = errorCountries.find((cou) => cou.countryISO3 === val);

                const countryErrorCode = selectedCountry?.errorCode;
                if (countryErrorCode) {
                    const msg = errorCodes[countryErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                }

                return true;
            }),
    });
export const useIsFinancialInstitution = () => {
    const userInfo = useSelector(selectUserInfo);
    return !!userInfo?.bFinancialInstitution;
};

export const getRelevantPayloadFields = (data: MakePaymentForm) => {
    const { id, transferType, paymentReference, amount, purpose, feeId, memo, attachments, notes } =
        data;

    return {
        id,
        transferType,
        paymentReference,
        amount,
        purpose,
        feeId,
        memo,
        attachments,
        notes,
    };
};

export const useCreatePayeeInitalValues = (payeeId?: number) => {
    const bFinancialInstitution = useIsFinancialInstitution();

    const [initialValues, setInitialValues] = useState<PayeeFormState | null>({
        ...EMPTY_PAYEE_VALUES,
        bFinancialInstitution,
        transmitter: bFinancialInstitution ? EMPTY_TRANSMITTER_INFO : null,
    });

    const { data, loading, error } = useFetch<ApiResponse<any, EditPayeeResponse>>(
        endpoints.accounts.editPayee,
        { params: { id: payeeId } },
        undefined,
        !payeeId
    );

    const createInitValues = (): PayeeFormState => {
        if (!data)
            return {
                ...EMPTY_PAYEE_VALUES,
                bFinancialInstitution,
                transmitter: bFinancialInstitution ? EMPTY_TRANSMITTER_INFO : null,
            };

        const {
            type,
            achAccountNumber,
            achRoutingNumber,
            postcode,
            transmitterFirstPartyTransfer,
            transmitterName,
            transmitterAccountNumber,
            transmitterAddressLine1,
            transmitterAddressLine2,
            transmitterTownCity,
            transmitterState,
            transmitterPostcode,
            transmitterCountryCode,
            transmitterType,
            transmitterDateOfBirth,
            ...values
        } = data.details;

        return {
            ...EMPTY_PAYEE_VALUES,
            bFinancialInstitution,
            payeeType: type,
            accountNumber: achAccountNumber,
            routingNumber: achRoutingNumber,
            postCode: postcode,
            transmitter: bFinancialInstitution
                ? {
                      firstPartyTransfer: transmitterFirstPartyTransfer,
                      type: transmitterType,
                      name: transmitterName,
                      dateOfBirth: transmitterDateOfBirth,
                      accountNumber: transmitterAccountNumber,
                      addressLine1: transmitterAddressLine1,
                      addressLine2: transmitterAddressLine2,
                      townCity: transmitterTownCity,
                      state: transmitterState,
                      postcode: transmitterPostcode,
                      countryCode: transmitterCountryCode,
                  }
                : null,
            ...values,
        };
    };

    useEffect(() => {
        const values: PayeeFormState = createInitValues();
        setInitialValues(values);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return { initialValues, loading, error };
};
